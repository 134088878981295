.ant-list-items {
  justify-content: center;
  flex-direction: row;
}

.question {
  width: 100%;

  .ant-list-item {
    width: 100%;
    display: block;
  }

  .tags {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr;
    font-size: 9.5pt;
    // font-weight: 600;
  }
}

.ant-typography {
  width: 100%;
}
