@import "~antd/dist/antd.css";

.App {
  text-align: center;
}

.site-layout-background {
  background: #eee;
}

.site-page-background {
  background: #fff;
}
