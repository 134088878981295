#editorjs {
  background-color: #fff;
}

.image-tool__caption,
.cdx-settings-button[data-tune="withBorder"],
.cdx-settings-button[data-tune="withBackground"],
.cdx-settings-button[data-tune="stretched"] {
  display: none;
}

.dashed {
  border: 0;
  border-top: 2px solid #222;
  margin-top: 30px;
}

.blank-lines {
  margin-top: 20px;
}

.math {
  margin-top: 20px;
}

.math-input {
  width: 100%;
  resize: vertical;
  border: 1px solid #999;
  border-radius: 3px;
}

.answer {
  background-color: lightgreen;
  border-radius: 5px;
  padding: 5px;
}

.answer::before {
  content: "Answer";
  font-weight: 800;
}
